import React from 'react'

import JobListing from 'components/careers/JobListing'

const FullStackDeveloper = () => (
  <JobListing
    name="Full Stack Developer"
    salary="$65k – $85k"
    overview={
      <>
        <p>
          We're looking for a mid-level Full Stack Developer (2-4 years
          experience) to join our growing team. You'll collaborate with other
          developers and designers on challenging client projects, and report
          directly to our Co-Founder & Technical Director.
        </p>
      </>
    }
    tasks={[
      'Execute full-stack development for a variety of clients',
      'Work collaboratively and proactively in a multidisciplinary team environment',
      'Participate in technical planning sessions to define models and architecture',
      'Collaborate with project managers and clients on extracting technical specs from feature requirements',
      'Contribute to our engineering culture',
    ]}
    qualifications={[
      '2-4 years of relevant work experience',
      'Solid experience in software engineering, including architecture and algorithms',
      'Extensive experience with React and Node.js',
      'Experience consuming and creating REST and GraphQL APIs',
      'Familiarity with SQL database architecture and querying',
      'Excellent communication skills',
      'Willingness to learn and grow',
      'Bonus: mobile development experience (Native/React Native)',
      'Bonus: familiarity with TypeScript',
      'Bonus: understanding of automated testing practices',
      'Bonus: experience setting up CI and deployment flows for containerized applications',
    ]}
  />
)

export default FullStackDeveloper
